import React from "react"

import Layout from "../../layouts/MainLayout/Layout"
import Seo from "../../config-components/seo"
import Wrapper from "../../components/Wrapper/Wrapper"

const PageNotFound = ({ location }) => {
  return (
    <Layout page={location.pathname}>
      <Seo
        title="Страница не найдена"
        description="Вы ищете страницу, которой не существует, или она была удалена"
        keywords="404"
      />
      <Wrapper width={1060}>
        К сожалению, данная страница не существует, либо была удалена.
      </Wrapper>
    </Layout>
  )
}

export default PageNotFound
